import {
  initBackgroundGridAnimation,
  initFooterC2aAnimation,
  refreshAllScrollTriggers
} from "../inits/_animatons.init.js";

initBackgroundGridAnimation()
initFooterC2aAnimation();
refreshAllScrollTriggers();


window.addEventListener('resize', () => {
  refreshAllScrollTriggers();
}, {passive: true});
